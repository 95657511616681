<template>
  <div>
     <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 lg12 mx-4 xl10>
        <v-layout wrap justify-center v-if="programData">
          <v-flex xs12 sm7 lg7 xl7 pt-4 px-2>
            <v-layout wrap justify-center>
              <v-flex xs12 text-start>
                <span class="itemHeading2" style="font-size:26px"> BOOKING </span>
              </v-flex>
              <v-flex xs12 sm5 pt-2 text-start align-self-center>
                <span class="itemTags" style="color: #9e9e9e">
                  Select an Available Date
                </span>
              </v-flex>
              <v-flex xs12 sm7 text-right align-self-center>
                <v-avatar color="deep-purple" tile size="15px"></v-avatar>
                <span class="itemText" style="color: #9e9e9e"> Holiday </span>
                <span class="pl-2">
                  <v-avatar color="#FF1313" tile size="15px"></v-avatar>
                  <span class="itemText" style="color: #9e9e9e">
                    Program not Available
                  </span>
                </span>
              </v-flex>
              <v-flex xs12 pt-2>
                <DatePicker @stepper="winStepper" />
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm5 lg5 xl5 v-if="programData._id">
            <CottageSlots
              v-if="programData.isCottage"
              v-bind:date="selectedDate"
              v-bind:storage="slots"
              v-bind:programData="programData"
              v-bind:programme="$route.query.type"
              :key="cottageKey"
            />
            <BookingSlots
              v-else
              v-bind:newDate="selectedDate"
              v-bind:storage="slots"
              v-bind:programme="programData"
              :key="selectedDate"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import DatePicker from "./datePicker";
import BookingSlots from "./bookingSlots";
import CottageSlots from "./cottageSlots";
export default {
  components: {
    DatePicker,
    BookingSlots,
    CottageSlots,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      selectedDate: this.formatDate2(new Date()),
      slots: [],
      programData: {
        isCottage: false,
      },
      cottageKey: 0,
      bookingKey: 0,
    };
  },
  beforeMount() {
    // this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/booking/ib/getslots",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          programme: this.$route.query.type,
          date: this.selectedDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            if (response.data.data) this.slots = response.data.data;
            if (response.data.programData)
              this.programData = response.data.programData;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    winStepper(windowData) {
      this.selectedDate = windowData.date;
      this.bookingKey++;
      this.cottageKey++;
      this.getData();
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 7) + " " + day + " " + year + " ";
      return strTime;
    },

    formatDate2(item) {
      var dt = new Date(item);
      var hours = dt.getHours();
      if (Number(hours) >= 17) {
        dt.setDate(dt.getDate() + 2);
        dt.setHours("00");
      }
      dt = new Date(dt);
      return dt;
    },
  },
};
</script>
<style scoped>
</style>