<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-dialog
      v-model="slotConfirmationDialog"
      persistent
      :width="
        $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
          ? '100vw'
          : $vuetify.breakpoint.name == 'md'
          ? '80vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '50vw'
          : '35vw'
      "
    >
      <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12>
            <SlotConfirmation
              @stepper="winStepper"
              v-bind:storage="programme"
              v-bind:selectedSlot="selectedSlot"
              :key="selectedSlot._id"
            />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-end>
      <v-flex lg11 xl11 text-left>
        <v-card color="#F7F7F7" tile flat>
          <v-layout wrap justify-center pa-4>
            <v-flex xs12 pt-6>
              <span class="itemHeading2"> BOOKING SUMMARY </span>
            </v-flex>
            <v-flex xs12 pt-2>
              <span class="itemTags" style="color: #9e9e9e"> Package </span>
            </v-flex>
            <v-flex xs12 pt-2>
              <span class="tableValue1">
                {{ $route.query.name }}
              </span>
              <span
                style="
                  font-family: poppinssemibold;
                  font-size: 14px;
                  color: #414141;
                "
              >
                {{ $route.query.caption }}
              </span>
            </v-flex>
            <v-flex xs12 pt-4>
              <span class="itemTags" style="color: #9e9e9e">
                Selected Date
              </span>
            </v-flex>
            <v-flex xs12 md12 align-self-center>
              <span class="itemHeading2"> {{ formatDate(newDate) }} </span>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center px-4 pb-4 v-if="newDate">
            <v-flex xs12>
              <span class="itemTags" style="color: #9e9e9e">
                Select Convenient Slot
              </span>
            </v-flex>
            <v-flex xs12>
              <v-card
                v-for="item in storage"
                :key="item._id"
                flat
                :ripple="false"
                class="my-2"
                :color="
                  selectedSlot._id == item._id
                    ? '#68D389'
                    : item.freeCount == 0
                    ? '#720000'
                    : '#FFFFFF'
                "
                @click="item.freeCount != 0 ? (selectedSlot = item) : {}"
              >
                <v-layout wrap justify-center pa-6>
                  <v-flex xs12>
                    <v-layout wrap justify-center>
                      <v-flex xs12 md6>
                        <span
                          class="tableValue1"
                          :style="{
                            color:
                              selectedSlot._id == item._id ||
                              item.freeCount == 0
                                ? '#FFFFFF'
                                : '#000000',
                          }"
                        >
                          {{ formatTime(item.startTime) }} -
                          {{ formatTime(item.endTime) }}
                        </span>
                      </v-flex>
                      <v-flex xs12 md6 pl-md-4>
                        <span
                          class="tableValue1"
                          :style="{
                            color:
                              selectedSlot._id == item._id ||
                              item.freeCount == 0
                                ? '#FFFFFF'
                                : '#000000',
                          }"
                        >
                          <span v-if="item.freeCount != 0">
                            {{ item.freeCount }}
                          </span>
                          <span v-else> No </span>
                          <span>Seats Available</span>
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 py-4>
              <v-divider></v-divider>
            </v-flex>
            <v-flex xs12>
              <span
                class="itemTags"
                style="color: #000000"
                v-if="storage.length < 1"
              >
                No Slot Available on {{ formatDate(newDate) }}
              </span>
            </v-flex>

            <template v-if="selectedSlot.freeCount > 0">
              <v-flex xs12 py-4>
                <v-text-field
                  class="textField2"
                  dense
                  type="number"
                  v-model="numberOfSeats"
                  outlined
                  label="No. of seats required"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 py-4>
                <v-text-field
                  class="textField2"
                  dense
                  v-model="reservedFor"
                  outlined
                  label="Reserved for (required)"
                  required
                ></v-text-field>
              </v-flex>

              <v-flex xs12 py-4>
                <v-btn
                  block
                  dark
                  depressed
                  color="#68D389"
                  :ripple="false"
                  @click="validateInput"
                >
                  Submit
                </v-btn>
              </v-flex>
            </template>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import SlotConfirmation from "./slotConfirmation";
export default {
  props: ["newDate", "storage", "programme"],
  components: { SlotConfirmation },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      selectedSlot: {},
      reservedFor: null,
      guest: [
        { name: "Indian", value: 0 },
        { name: "Foreigner", value: 0 },
        { name: "Children", value: 0 },
      ],
      totalGuest: 0,
      slotConfirmationDialog: false,
      numberOfSeats: null,
    };
  },
  mounted() {
    for (var i = 0; i < this.storage.length; i++) {
      if (this.storage[i].status == "Active") {
        this.selectedSlot = this.storage[i];
        return;
      }
    }
  },
  watch: {
    numberOfSeats() {
      if (this.numberOfSeats > this.selectedSlot.freeCount) {
        this.msg =
          "Please select maximum of " +
          this.selectedSlot.freeCount +
          " rooms";
        this.showSnackBar = true;
        this.numberOfSeats = null;
      }
    },
    storage: {
      handler() {
        for (var i = 0; i < this.storage.length; i++) {
          if (this.storage[i].status == "Active") {
            this.selectedSlot = this.storage[i];
            return;
          }
        }
      },
      deep: true,
    },
    selectedSlot: {
      handler(val) {
        if (val.freeCount < this.totalGuest) {
          for (var i = 0; i < this.guest.length; i++) {
            this.guest[i].value = 0;
          }
        }
      },
      deep: true,
    },

    guest: {
      handler(val) {
        this.totalGuest = val.reduce(function (prev, cur) {
          return prev + cur.value;
        }, 0);
      },
      deep: true,
    },
  },
  methods: {
    validateInput() {
      if (this.numberOfSeats > this.selectedSlot.freeCount) {
        this.msg =
          "Please select maximum of " +
          this.selectedSlot.freeCount +
          " rooms";
        this.showSnackBar = true;
      } else if(!this.reservedFor) {
        this.msg="Please provide the guest name"
        this.showSnackBar=true
      } else this.addToCart();
    },
    winStepper(windowData) {
      this.slotConfirmationDialog = windowData.dialog;
      if (windowData.accept) this.addToCart();
    },
    addToCart() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/reserve/ib/add",
        data: {
          programme: this.$route.query.type,
          bookingDate: this.newDate,
          slotDetail: this.selectedSlot._id,
          guestCount: Number(this.numberOfSeats),
          reservedFor: this.reservedFor
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg =response.data.msg;
            this.showSnackBar = true;
            this.numberOfSeats=null
            this.reservedFor= null
            // this.$router.push("/program/" + this.$route.query.type);
          } else {
            this.msg =response.data.msg;
            this.showSnackBar = true;
            if (response.data.expired) {
              this.$router.push("/program/" + this.$route.query.type);
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 7) + " " + day + " " + year + " ";
      return strTime;
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
};
</script>
<style>
.v-application .primary--text {
  color: #68d389 !important;
  caret-color: #68d389 !important;
}
#slotInput {
  text-align: center;
}
</style>